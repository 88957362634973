import { ICoreContext } from '@msdyn365-commerce/core';
import { AttributeValue } from '@msdyn365-commerce/retail-proxy';
import { getAttributesLocale, getAttributeNumberOrDefault } from '../../shared-utils/attributes';

export function getTemperatureZone(lang: string, productSpecificationData: AttributeValue[] | undefined): string | undefined {
    if (!productSpecificationData) {
        return undefined;
    }

    const locale = getAttributesLocale(lang);
    // Use 10 as default value if no temperature is set
    const maxTemp = getAttributeNumberOrDefault(productSpecificationData, locale.temperatureMax, 10);

    if (maxTemp < 0) {
        return locale.frozen;
    } else if (maxTemp <= 4) {
        return locale.cool;
    } else {
        return locale.dry;
    }
}

export function getTemperatureZoneIcon(context: ICoreContext, temperatureZone: string): string | undefined {
    const locale = getAttributesLocale(context.request.locale);

    switch (temperatureZone) {
        case locale.frozen:
            return 'frozen';
        case locale.cool:
            return 'cool';
        default:
            return undefined;
    }
}
