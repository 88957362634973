import * as React from 'react';
import classnames from 'classnames';
import { ICoreContext, Image, IImageSettings } from '@msdyn365-commerce/core';

interface IDiscountBadgeProps {
    context: ICoreContext;
    className?: string;
    badge: string | undefined;
    size: 'small' | 'medium' | 'large' | 'x-large';
}

function getDiscountBadgeSize(size: 'small' | 'medium' | 'large' | 'x-large'): { w: number, h: number } {
    switch (size) {
        case 'small':
            return { w: 48, h: 48 };
        case 'medium':
            return { w: 56, h: 56 };
        case 'large':
            return { w: 72, h: 72 };
        case 'x-large':
        default:
            return { w: 72, h: 72 };
    }
}

function getImageSettings(size: 'small' | 'medium' | 'large' | 'x-large'): IImageSettings {
    const { w, h } = getDiscountBadgeSize(size);

    return {
        viewports: {
            xs: { w, h, q: `w=${w}&h=${h}&q=80&m=0&f=png` },
            lg: { w, h, q: `w=${w}&h=${h}&q=80&m=0&f=png` },
            xl: { w, h, q: `w=${w}&h=${h}&q=80&m=0&f=png` }
        },
        quality: 80
    };
}

const DiscountBadge = (props: IDiscountBadgeProps) => {
    const baseClassName = 'dlvry-discount-badge';

    if (!props.badge) {
        return null;
    }

    let baseImageUrl = props.context.request.apiSettings.baseImageUrl;

    if (baseImageUrl.endsWith('/')) {
        baseImageUrl = baseImageUrl.slice(0, -1);
    }

    return (
        <div className={classnames(baseClassName, props.className, props.size)}>
            <Image
                requestContext={props.context.request}
                src={baseImageUrl + props.badge}
                gridSettings={props.context.request.gridSettings!}
                imageSettings={getImageSettings(props.size)}
                loadFailureBehavior='empty'
            />
        </div>
    );
};

export default DiscountBadge;
